// DateSelector.js
import { useState,useEffect } from 'react';
import  { formatShortDate,  getItem }  from '../../utils';

const GridOverview = ({ availableTimes,availableTables,selectedDate, overviewVisible }) => {

    const [elements, setElements] = useState([]);
    const [bookedAllItems, setBookedAllItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {

        const fetchAllData = async () => {
            try {    
                setIsLoading(true); // Start loading    
                const response = await getItem({
                    date: formatShortDate(selectedDate, 'unformated'),
                    action: 'get-all-booked-items'
                });     
                setBookedAllItems(response);
                setElements(generateList(response));
                
            } catch (error) {
                console.error('Error fetching booked items:', error);
            }
            finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchAllData();
        console.log(elements, 'test')
    }, [overviewVisible,selectedDate]);



    function generateList(bookedAllItems) {    

        const rows = availableTimes.length - 1;
        const columns = availableTables.length - 1;
        const grid = [];

        const itemsId = availableTimes.map(item => item.id);
        const itemsName = availableTables.map(item => item.id);        
    
        for (let row = 0; row <= rows; row++) {
            for (let col = 0; col <= columns; col++) {
                const itemNumber = (row - 1) * columns + col;
                if (itemNumber <= 289) {

                // Kollar om en bokning med en viss time och table id har matchande
                // värde i bookedAllItems objektet
                const isUnselectable = bookedAllItems.find(
                    item => item.timeId === String(itemsId[row]) && item.tableId === String(itemsName[col])                   
                );

                // Visar namn på den som bokat
                let bookingId = isUnselectable ? 
                    `Namn: ${isUnselectable.firstname} ${isUnselectable.lastname}<br />Tel: ${isUnselectable.tel}`
                    :''
                ;

                

                // unavailable = obokbar då tiden har gått ut
                // unselectable = obokbar då den renad är bokad/avaktiverad
                // selectable = går att boka
                let classNames = 'selectable';

                if(bookingId) {
                    classNames = classNames.concat(' booked');
                }

                const itemKey = `row-${row}-col-${col}`;
      
                grid.push(
                    <div
                        key={itemKey}
                        data-id-time={itemsId[row]}
                        data-id-table={itemsName[col]}
                        className={`
                            list-item grid-item 
                            ${classNames}                            
                        `}
                    >
                        {availableTimes[row]['type_of_object']}  

                        <br />
                    </div>
                    );
                }
            }
        }
        return grid;
    }  



    return (

        <div id="desktop-grid-items" className={`flex ${overviewVisible ? 'scale-1' : 'scale-0'} position-fixed`}>

            <div id="items" className='grid flex-1'>
                
                <div className="list-item list-item-header">Pool 1</div>
                <div className="list-item list-item-header">Pool 2</div>
                <div className="list-item list-item-header">Pool 3</div>
                <div className="list-item list-item-header">Pool 4</div>
                <div className="list-item list-item-header">Pool 5</div>
                <div className="list-item list-item-header">Pool 6</div>
                <div className="list-item list-item-header">Pool 7</div>
                <div className="list-item list-item-header">Pool 8</div>
                <div className="list-item list-item-header">Pool 9</div>
                <div className="list-item list-item-header">Pool 10</div>
                <div className="list-item list-item-header">Pool 11</div>
                <div className="list-item list-item-header">Darts 1</div>
                <div className="list-item list-item-header">Darts 2</div>
                <div className="list-item list-item-header">Darts 3</div>
                <div className="list-item list-item-header">Darts 4</div>
                <div className="list-item list-item-header">Darts 5</div>

                {isLoading ? 
                    (
                        <div className="spinner"></div>
                    ) : (
                        elements.length > 0 ? elements : <div>No items available</div>
                    )
                }
                
            </div>
        </div>
    );
};

export default GridOverview;