import sprite from '../images/icons.svg';

// Vafö heiter e DateSelector å int MonthSelector!
const DateSelector = ({ selectedDate, setSelectedDate, lang = 'sv-SE' }) => {  

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight to only compare dates

    // Define the formatting options
    const options = { month: 'long', year: 'numeric' };

    // Use the 'sv-SE' locale to get the month name in Swedish
    const formattedDate = selectedDate.toLocaleDateString(lang, options);

    // Replace the default space separator between month and year with ' - '
    const formattedString = formattedDate.replace(' ', ' - ');

    // Handle going back by one month
    const handlePreviousMonth = () => {
        const previousMonth = new Date(selectedDate);
        previousMonth.setMonth(selectedDate.getMonth() - 1);

        setSelectedDate(previousMonth);
    };

    // Handle going forward by one month
    const handleNextMonth = () => {
        const nextMonth = new Date(selectedDate);
        nextMonth.setMonth(selectedDate.getMonth() + 1);
        setSelectedDate(nextMonth);
    };
    
    return (
        <span className="select-date flex flex-align-center-h wrapper-width center">

            <svg onClick={handlePreviousMonth} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} width="31" height="27">
                <use href={sprite + "#svg-arrow-right"} />
            </svg>

            {formattedString}

            <svg onClick={handleNextMonth} style={{ cursor: 'pointer' }} width="31" height="27">
                <use href={sprite + "#svg-arrow-right"} />
            </svg>
            
        </span>
    );
};

export default DateSelector;
