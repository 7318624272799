import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from '../../utils';

const ExportToExcel = ({ data,selectedDate }) => {
    
    const { t } = useTranslation();

    const columnMapping = {
        firstname: 'Förnamn',
        lastname: 'Efternamn',
        email: 'E-post',
        member_fee: 'Medlemsavgift',
        total_bookings: 'Bokningsavgifter',
        total_with_fee: 'Avgifter totalt'
      };

    const filteredData = data
    .filter(item => item.total_with_fee !== 0) // Remove rows where totla_fee is '0'
    .map(({ id, ...item }) => item)
    .map(({ type_of_member, ...item }) => item)
    .map(({ member_payment_handeld_date, ...item }) => item); // Remove the userLevel field    

     // Map the data keys to new column headers
    const mappedData = filteredData.map(item => {
        const mappedItem = {};
        for (const key in item) {
        if (columnMapping[key]) {
            mappedItem[columnMapping[key]] = item[key]; // Use the mapped column name
        } else {
            mappedItem[key] = item[key]; // Keep original if no mapping
        }
        }
        return mappedItem;
    });

    

    const generateExcel = () => {
        if (!mappedData || !mappedData.length) {
            alert("No data to export");
            return;
        }

        // Create a worksheet from the data array
        const worksheet = XLSX.utils.json_to_sheet(mappedData);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        // Write the workbook and trigger the download
        XLSX.writeFile(workbook, `member-list-${formatShortDate(selectedDate,'unformated')}.xlsx`);
    };

    return (
        <button style={{background: 'var(--main-color)', padding: '5px 10px' ,borderRadius: '6px', margin: '-10px auto 10px', display:'block'}} onClick={generateExcel}>
            {t('export-to-xlsx')}
        </button>
    );
};

export default ExportToExcel
